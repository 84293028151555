<template>
	<div id="verification">
		<div v-loading="loading">
			<div class="headbox">身份信息核验, <span style="color: #1989fa;cursor: pointer;" @click="downloadTemplate()">点击下载模板</span></div>
			
			<div class="tips">
				<el-alert
				    title="请下载模板，按照模板格式上传数据"
				    type="warning"
					:closable='false'
				    description="姓名、身份证必须填写完整，否则校验时将会跳过该条数据，请保护好当前页面地址，避免地址外漏造成资源浪费"
				    show-icon>
				</el-alert>
			</div>
			
			<div class="file">
				<div v-if="file" class="filebox" >
					<div><i class="el-icon-document"></i> {{file.name}}</div>
					<div style="display: flex;">
						<div class="reload" style="color: #F00;" @click="handReload()">重新选择</div>
						<div class="reload" @click="uploadFile()">开始核验</div>
					</div>
				</div>
				<div v-else class="uploadbox" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover" @click="$refs['upload-input'].click()">
					<input ref="upload-input" class="upload-input" type="file" accept=".xlsx, .xls" @change="handleClick">
					<i class="el-icon-upload uploadIcon"></i>
					<div>拖动文件至此处或<span style="color: #1989fa;">点击上传</span></div>
				</div>
			</div>
			
			<div class="headbox">有误数据</div>
			<div style="padding: 0 18px;width: 800px;">
				<el-table :data="errorList" border style="width: 100%">
				    <el-table-column prop="index" width="60" label="行数" align="center"/>
				    <el-table-column prop="name" width="140" label="姓名" align="center"/>
				    <el-table-column prop="id_number" width="180" label="身份证" align="center"/>
				    <el-table-column prop="msg" label="错误原因" align="center"/>
				</el-table>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {eltips} from '@/util/util.js'
	export default {
		name: "verification",
		data() {
			return {
				loading:false,
				file:null,
				errorList:[],
			};
		},
		created() {
			
		},
		methods:{
			uploadFile(){
				this.loading = true
				let formData = new FormData();
				formData.append('file',this.file);
				this.axios.post('/api/importInduction',formData).then(res=>{
					this.loading = false
					if(res.code==200){
						if(res.data.length==0){
							this.file = null
							eltips('校验成功，数据正确','success')
						} else{
							eltips('数据有误','warning')
						}
						this.errorList = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			handReload(){
				this.file = null
				// this.$nextTick(()=>{
				// 	this.$refs['upload-input'].click()
				// })
			},
			handleClick(e){
				if(e.target.files.length==0) return this.$refs['upload-input'].value = null;
				const file = e.target.files[0]
				if(!this.isExcel(file)) return eltips('仅支持 .xlsx, .xls 文件','error');
				this.file = file
				this.$refs['upload-input'].value = null
			},
			handleDrop(e) {
				e.stopPropagation()
				e.preventDefault()
				if (this.loading) return
				const files = e.dataTransfer.files
				if (files.length !== 1) return eltips('请上传单个文件', 'error');
				const file = files[0]
				if(!this.isExcel(file)) return eltips('仅支持 .xlsx, .xls 文件','error');
				this.file = file
				this.$refs['upload-input'].value = null
			},
			handleDragover(e) {
			  e.stopPropagation()
			  e.preventDefault()
			  e.dataTransfer.dropEffect = 'copy'
			},
			downloadTemplate(){
				this.loading = true
				this.axios.get(`/excelTemplate/身份信息校验模板.xlsx?v=${new Date().getTime()}`,{responseType: 'blob'}).then(response => {
					this.loading = false
					let blob = new Blob([response], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					if (window.navigator.msSaveOrOpenBlob) {
						//兼容ie
						window.navigator.msSaveBlob(blob, '身份信息校验模板.xlsx');
					} else {
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob); //创建下载的链接
						downloadElement.href = href;
						downloadElement.download = '身份信息校验模板.xlsx'; //下载后文件名
						document.body.appendChild(downloadElement);
						//此写法兼容火狐
						let evt = document.createEvent("MouseEvents");
						evt.initEvent("click", false, false);
						downloadElement.dispatchEvent(evt);
						document.body.removeChild(downloadElement);
					}
				})
			},
			isExcel(file) {
			  return /\.(xlsx|xls|csv)$/.test(file.name)
			}
		}
	};
</script>

<style lang="less" scoped>
	#verification{
		min-height: 100vh;
		background: #FFFFFF;
	}
	.headbox{
		padding: 18px;
		font-size: 16px;
		font-weight: bold;
	}
	.tips{
		width: 800px;
		padding:0 18px;
	}
	
	.file{
		padding: 18px;
		width: 800px;
		.filebox{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.reload{
				line-height: 1;
				color: #1989fa;
				cursor: pointer;
				margin-left: 8px;
			}
		}
		
		.uploadbox{
			width: 100%;
			height: 200px;
			border: 1px solid #DCDFE6;
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&:hover{
				border-color: #1989fa;
				border-style: dashed;
			}
			.uploadIcon{
				font-size: 68px;
				color: #c0c4cc;
				line-height: 1;
				margin-bottom: 12px;
			}
			.upload-input{
			  display: none;
			  z-index: -9999;
			}
		}
	}
	
	.tablebox{
		padding: 0 18px;
	}
</style>